import React from 'react';
import LocalizedStrings from 'localized-strings';
import { Root, Header } from './styles';
import stringsToLocalize from '../../../localisation';

const strings = new LocalizedStrings(stringsToLocalize);
const NotMobile = (props) => {
  let lang = 'En';
  if (props.Language) {
    lang = props.Language;
    strings.setLanguage(lang);
  }
  return (
    <Root>
      <Header>
        <p dir={strings.dir}>{strings.notMobile}</p>
      </Header>
    </Root>
  );
};

export default NotMobile;
