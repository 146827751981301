import React from 'react';
import LocalizedStrings from 'localized-strings';
import { Root, Header } from './styles';
import stringsToLocalize from '../../../localisation';

const strings = new LocalizedStrings(stringsToLocalize);
const IOS = (props) => {
  strings.setLanguage(props.Language);
  return (
    <Root>
      <Header>
        <p dir={strings.dir}>{strings.ios.support}</p>
      </Header>
    </Root>
  );
};

export default IOS;
