import React from 'react';
import LocalizedStrings from 'localized-strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Scrollable, Content, Check } from './styles';
import stringsToLocalize from '../../../localisation';
import Sanitizer from '../../../utils/sanitizer';
import constants from '../../../utils/constants';

const strings = new LocalizedStrings(stringsToLocalize);
const ThankYou = (props) => {
  strings.setLanguage(props.Language);
  let successText = null;
  if (props.SuccessText) {
    successText = props.SuccessText;
  } else {
    successText = strings.thankyou;
  }
  return (
    <Scrollable height={window.innerHeight + 60}>
      <Check>
        <FontAwesomeIcon
          icon={faCheck}
          color={constants.success}
          size='4x'
        ></FontAwesomeIcon>
      </Check>
      <Content
        dir={strings.dir}
        dangerouslySetInnerHTML={{ __html: Sanitizer(successText) }}
      ></Content>
    </Scrollable>
  );
};

export default ThankYou;
