import styled from 'styled-components';
import constants from '../../utils/constants';

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  img {
    margin-top: 20px;
    width: 40%;
  }
  ul {
    list-style-position: inside;
    display: inline;
    list-style: none;
    list-style-type: none;
    padding-top: 5%;
    color: ${constants.primary};
    font-size: 17px;
    li {
      text-align: center;
      text-justify: inter-word;
    }
  }
`;
export const DIV = styled.div`
  margin-top: 0;
  color: ${constants.primary};
  font-size: 1em;
  ${({ dir }) => {
    if (dir.toLowerCase() === 'ltr') return `margin-left: 0.5em`;
    return `margin-right: 0.5em`;
  }}
`;
