import React from 'react';
import IOs from './IOs';
import Chrome from './Android/Chrome';
import Firefox from './Android/Firefox';
import Other from './Android/Other';
import { Root, P } from './styles';

import { isIOS, isChrome, isAndroid, isFirefox } from 'react-device-detect';
const Blocked = (props) => {
  if (isIOS) {
    return <IOs {...props}></IOs>;
  }
  if (isAndroid) {
    if (isChrome) return <Chrome {...props}></Chrome>;
    if (isFirefox) return <Firefox {...props}></Firefox>;
    return <Other {...props}></Other>;
  }

  return (
    <Root>
      <P dir={props.dir}>{props.text || `BLOCKED OTHER`}</P>
    </Root>
  );
};

export default Blocked;
