import styled from 'styled-components';
import constants from '../../utils/constants';

export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 0 100px;
`;
export const Header = styled.h1`
  color: ${constants.danger};
  font-size: 80px;
`;
