import React, { useRef, useState, useEffect } from 'react';
import Measure from 'react-measure';
import LocalizedStrings from 'react-localization';
import { useCardRatio } from '../../hooks/useCardRatio';
import { useOffsets } from '../../hooks/useOffsets';
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
  Button,
  SubmitButton,
  Buttons,
  ButtonAnotherPhoto
} from './styles';
import Loading from '../Loading';
import stringsToLocalize from '../../localisation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

let strings = new LocalizedStrings(stringsToLocalize);

export function Camera({
  onCapture,
  onClear,
  onSubmit,
  Language,
  onError,
  setIsLoading,
  cameraMode
}) {
  
  strings.setLanguage(Language);
  const canvasRef = useRef();
  const videoRef = useRef();
  const locationRef = useRef();
  const err = useRef();
  const requestedMedia = {
    audio: false,
    video: {
      facingMode: cameraMode,
      zoom: true
    },
  };

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);
  
  const [mediaStream, setMediaStream] = useState(null);
  const [loading, setLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState(null);
  const [maxWidth, setMaxWidth] = useState(null);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );
 
  useEffect(() => {
    return function cleanup() {
      if (!!mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [mediaStream]);

  useEffect(() => {
    if (!mediaStream) {
      enableVideoStream();
    }
    if ('geolocation' in navigator) {
      locationRef.current = {};
      navigator.geolocation.getCurrentPosition(
        function (position) {
          locationRef.current.latitude = position.coords.latitude;
          locationRef.current.longitude = position.coords.longitude;
        },
        function (errx) {
          err.current = 'geo';
        }
      );
    } else {
      err.current = 'geo';
    }
    if (err.current) {
      onError(err.current);
    }
    

  }, [enableVideoStream, onError, mediaStream]);
  useEffect(() => {
    if (!videoRef.current) return;
    if (maxHeight == null) {
      setMaxHeight(videoRef.current.videoHeight);
    } else {
      if (videoRef.current && videoRef.current.videoHeight !== 0) {
        setMaxHeight(videoRef.current.videoHeight);
      }
    }
    if (maxWidth == null) {
      setMaxWidth(videoRef.current.videoWidth);
    } else {
      if (videoRef.current && videoRef.current.videoWidth !== 0) {
        setMaxWidth(videoRef.current.videoWidth);
      }
    }
  }, [
    maxHeight,
    maxWidth,
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
  ]);

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }
  if (!mediaStream) {
    return null;
  }
  let btn = null;
    if(isCanvasEmpty){
      btn = <Button onClick={handleCapture}>{strings.takePhoto}</Button>
    }else{
      btn = <ButtonAnotherPhoto onClick={handleClear}>{strings.takeAnotherPhoto}</ButtonAnotherPhoto>
    }
      
  async function enableVideoStream() {
    setIsVideoPlaying(true);
    setLoading(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(requestedMedia);
      setMediaStream(stream);
      videoRef.current.srcObject = stream;
      const [videoTrack] = stream.getVideoTracks();
      const settings = videoTrack.getSettings();
      if('zoom' in settings){
          await videoTrack.applyConstraints({ advanced: [{ zoom: 0 }] });
      }
      
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      err.current = 'media';
      setIsLoading(false);
    }
  }
  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio) + 35,
    });
  }

   function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  function handleCapture() {
    const context = canvasRef.current.getContext('2d');

    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );

    canvasRef.current.toBlob(
      (blob) => {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });

        onCapture(blob);
      },
      'image/jpeg',
      1
    );
    setIsCanvasEmpty(false);
    setIsFlashing(true);
    onClear();
  }

  function handleClear() {
    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
    enableVideoStream();
  }
  function handleSubmit() {
    canvasRef.current.toBlob(
      (blob) => onSubmit(blob, locationRef.current),
      'image/jpeg',
      1
    );
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <Wrapper>
              <Container
                ref={measureRef}
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                style={{
                  height: `${container.height}px`,
                }}
              >
                <Video
                  ref={videoRef}
                  hidden={!isVideoPlaying}
                  onCanPlay={handleCanPlay}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    top: `-${offsets.y}px`,
                    left: `-${offsets.x}px`,
                  }}
                />

                <Overlay hidden={!isVideoPlaying} />

                <Canvas
                  ref={canvasRef}
                  width={container.width}
                  height={container.height}
                />

                <Flash
                  flash={isFlashing}
                  onAnimationEnd={() => setIsFlashing(false)}
                />
              </Container>
              <Buttons>
                
                {isVideoPlaying && !isCanvasEmpty && (
                  <SubmitButton onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faPaperPlane} flip={"horizontal"} /><br/>
                    {strings.submit}
                  </SubmitButton>
                )}
                {isVideoPlaying && btn}
              </Buttons>
            </Wrapper>
          )}
        </Measure>
      )}
    </>
  );
}
