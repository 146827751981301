import React from 'react';
import LocalizedStrings from 'localized-strings';
import { Root, Header, URL } from './styles';
import stringsToLocalize from '../../../localisation';

const strings = new LocalizedStrings(stringsToLocalize);
const Android = (props) => {
  let lang = 'En';
  if (props.Language) {
    lang = props.Language;
    strings.setLanguage(lang);
  }
  const chromeAppstorURL = `https://play.google.com/store/apps/details?id=com.android.chrome&hl=${lang}`;
  strings.setLanguage(props.Language);
  return (
    <Root>
      <URL>
        <Header>
          <p dir={strings.dir}>
            {strings.android.support}{' '}
            <a href={chromeAppstorURL}>{strings.android.url1}</a>{' '}
            {strings.android.url2}
          </p> 
          
        </Header>
      </URL>
    </Root>
  );
};

export default Android;
