import styled from 'styled-components';
import constants from '../../../utils/constants';

export const Content = styled.main`
  text-align: center;
  color: ${constants.primary};
  font-size: 25px;
`;
export const Scrollable = styled.div`
  overflow-y: auto;
  height: ${({ height }) => height && `${height}px`};
  padding-bottom: 1em;
  margin-top: 2em;
  width: 100%;
`;
export const Check = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
`;
