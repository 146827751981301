import React from 'react';
import { Heading } from './styles';

const Header = (props) => {
  return (
    <Heading demo={props.demo}>
      <h1>
        {props.title ? props.title : 'UNHCR Remote Verification'}{' '}
        {props.demo ? `(demo)` : ``}
      </h1>
    </Heading>
  );
};
export default Header;
