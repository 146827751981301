import styled from 'styled-components';
import constants from '../../utils/constants';

export const Preview = styled.img`
  width: 100%;
  height: auto;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  button {
    margin-bottom: 1.5em;
  }
`;
export const Button = styled.button`
  width: ${({width}) => width ? width : `75%` };
  min-width: 100px;
  max-width: 250px;
  margin-top: 24px;
  padding: 7px 12px;
  font-size: ${({fontSize}) => fontSize ? fontSize : `16px`};
  background-color: ${({ textColor }) =>
    textColor ? constants[textColor] : constants.primary};
  color: ${constants.white};
  border: 0px;
  border-radius: 7px;
  appearance: none;
  cursor: pointer;
  height: ${({height}) => height ? height : `auto`};
`;
export const X = styled.div`
display: flex;
direction: row;
justify-content: space-between;
align-items: space-between;
height: 2em;
`;