import React, { useEffect, useState, useRef } from 'react';
import PermissionPage from '../PermissionPage';
import Page404 from '../404';
import BrowserNotSupported from '../BrowserNotSupported';
import axios from 'axios';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import config from '../../config';
import {
  isMobile,
  isAndroid,
  isChrome,
  isIOS,
  isMobileSafari,
  osName,
  osVersion,
  fullBrowserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
  deviceDetect,
} from 'react-device-detect';

const Intro = (props) => {
  const [data, setData] = useState(null);
  const [demo, setDemo] = useState(false);
  const [error, setError] = useState(null);
  const [guid, setGUID] = useStateWithCallbackLazy(null);
  const deviceDetectx = deviceDetect();
  const deviceLog = useRef({
    osName,
    osVersion,
    fullBrowserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
  });
  deviceLog.current.Dev = deviceDetectx;
  deviceLog.current.Checks = {
    isMobile,
    isAndroid,
    isChrome,
    isIOS,
    isMobileSafari,
  };

  useEffect(() => {
        setGUID(props.match.params.guid, (guid) => {
          if (!data) getData(guid);
          logData(deviceLog, guid);
        })

    const getData = async (g) => {
      let baseURL = config.baseURL;
      let url = `${baseURL}/web/${g}`;
      try {
        let d = await axios.get(url);
        if (d) {
          setData(d);
          setDemo(d.data.Demo);
          if(d.data.GUID !== g){
            window.location.href = `${baseURL}/web/${d.data.GUID}`
          }
        }
      } catch (e) {
        setError('404');
      }
    };
    const logData = async (deviceLog, g) => {
      let baseURL = config.baseURL;
      await axios.post(`${baseURL}/web/log/${g}`, {
        data: JSON.stringify(deviceLog.current),
      });
    };
    
  }, [data, props.match.params.guid]);
  return (
    <>
      {data && (
        <div>
          {
            //browserSupported &&
            (isMobile || isAndroid || isIOS) && (
              <PermissionPage
                guid={guid}
                {...data}
                demo={demo}
              />
            )
          }
          {(!isMobile || (!isAndroid && !isIOS)) && (
            <BrowserNotSupported guid={guid} {...data} />
          )}
        </div>
      )}
      {error && <Page404 {...props} />}
    </>
  );
};
export default Intro;
