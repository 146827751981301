import React from 'react';
import Sanitizer from '../../utils/sanitizer';

import { P } from './styles';
const IntroInstructions = (props) => {
  return (
    <>
      <P
        dangerouslySetInnerHTML={{ __html: Sanitizer(props.IntroText) }}
        dir={props.dir}
      ></P>
    </>
  );
};
export default IntroInstructions;
