export default {
  dir: 'ltr',
  openCamera: 'Open Camera',
  closeCamera: 'Close Camera',
  takePhoto: 'Take Photo',
  takeAnotherPhoto: 'Take another picture',
  verificationNotFound: 'The requested URL was not found',
  loading: 'Loading',
  submit: 'Submit',
  thankyou:
    'Thank you for submitting your photo, we will review it and continue the verification process',
  error: 'There was an error, please try again or contact UNHCR',
  android: {
    support: 'This browser is not supported',
    url1: 'Click here',
    url2: 'to download Chrome for Android',
  },
  ios: {
    support: 'This browser is not supported, please use Safari',
  },
  notMobile: 'Computer browser is not supported, please use a mobile device',
  notSupported:
    'This device is not supported, please use Chrome on Android or Safari on iOS',
  title: 'UNHCR Remote Verification',
  introText: `<ol>
  <li>Click on </strong>Open Camera<strong></li>
  <li>Click on <strong>Take Photo</strong> to take a selfie</li>
  <li>Click on <strong>Send</strong> so that UNHCR can verify your identity</li>
  </ol>
  <u><em>Your photo will be safely stored in our database with your other documents</em></u>`,
  geo: 'Geo location is not allowed, please set the permission from settings',
  media: 'Camera mulfunction or Permission to use the Camera is not allowed',
  submitAnother: 'Submit Another',
  confirmAnother: 'Do you want to submit another?',
  yes: 'Yes',
  no: 'No',
  enableLocation: 'Enable Location',
  enableCamera: 'Enable Camera',
  enableLocationCheck: 'Check if Location Available',
  enableCameraCheck: 'Check if Camera Available',
  homeIntroText: `<h3>This link will expire in <u>30 minutes</u></h3>
  The link of this page is sent by UNHCR in Lebanon in order to renew your information and file remotely instead of having you and your family members coming in person to the Refugee Reception Centers. To process, you need to:
  <ol><li>Have a smart phone with internet access, and a functional camera</li>
  <li>have access to the internet during the interview</li>
  <li>Your browser phone must be allowed to access to your location and camera</li></ol>
  <em>Click on the respective buttons below to enable Camera and Location Services</em>
  `,
  demoHomeIntroText: `The link of this page is sent by UNHCR in Lebanon in order to assess the possibility of renewing your information and file remotely instead of having you and your family members coming in person to the Refugee Reception Centers. To process, you need to:
  <ol><li>Have a smart phone with internet access, and a functional camera</li>
  <li>have access to the internet during the phone remote interview</li>
  <li>Your browser phone must be allowed to access to your location and camera</li></ol>
  `,
  locationSuccess: `Location Enabled Successfully`,
  cameraSuccess: `Camera Enabled Successfully`,
  steps: {
    first: `First Step:`,
    enableCamera: `To check if your browser allows you to use the camera, click on the button below and you will be asked to grant permission to the site to use your Camera, click on <strong>allow</strong>`,
    second: `Second Step:`,
    enableLocation: `To check if your browser allows you to use the Location Services, click on the button below and you will be asked to grant permission to the site to use your Location, click on <strong>allow</strong>`,
    last: `Last Step:`,
    testCamera: `Click on <b>Open Camera</b> to open the camera and take a photo.
    Once you do click on submit to check if everything is working as it should.`,
  },
  successText: `You have successfully passed the test, you can use this device to go through the remote verification on $APTDATE$. Please make sure all your family member are there and ready to verify on the mentioned date.`,
  errorRefresh: `There was an error, please refresh page`,
  nf: `No Face Detected`,
  bp: `There was an error with the photo sent. please try again`,
  ok: `Ok`,
  tryAgain: `Try Again`,
  blocked: {
    ios: {
      location: `<strong>Location is blocked:</strong><br>
      Make sure the location service is enabled on the Operating System<br>
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)
      `,
      camera: `<strong>Camera is blocked:</strong><br>
      Make sure Safari has access to the camera<br>
      Go to: <strong>Settings</strong> -> <strong>Safari</strong> -> scroll down to <u>SETTINGS FOR WEBSITES</u> -> <strong>Camera</strong> tick <strong>Allow</strong>
      `,
    },
    android: {
      firefox: {
        location: `<strong>Location is blocked:</strong>
      Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)
      `,
      },
      chrome: {
        location: `<strong>Location is blocked:</strong>
      1- Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)<br>
      2- Make sure the location is not blocked on Chrome browser
      On the browser tap on:
      on the top right corner -> <strong>Settings</strong> -> <strong>Site Settings</strong> -> <strong>Location</strong>
      `,
        camera: `<strong>Location is blocked:</strong>
      1- Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)<br>
      2- Make sure the location is not blocked on Chrome browser
      On the browser tap on:
      on the top right corner -> <strong>Settings</strong> -> <strong>Site Settings</strong> -> <strong>Location</strong>
      `,
      },
      other: {
        location: `<strong>Location is blocked:</strong>
      Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> > <strong>Privacy</strong> > <strong>Location Services</strong> (should be On)
      `,
      },
    },
  },
};
