import styled, { css, keyframes } from 'styled-components';
import constants from '../../utils/constants';

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;
const beepAnimation = keyframes`
0%
{
  transform: scale( .75 );
}
20%
{
  transform: scale( 1 );
}
40%
{
  transform: scale( .75 );
}
60%
{
  transform: scale( 1 );
}
80%
{
  transform: scale( .75 );
}
100%
{
  transform: scale( .75 );
}
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  ${
    ({maxWidth}) => maxWidth ? `max-width: ${maxWidth}px` : `max-width: 100%`
  };
  ${
    ({maxHeight}) => maxHeight ? `max-height: ${maxHeight}px` : `max-width: 100%`
  };
  width: 100%;
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Video = styled.video`
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  border-radius: 10px;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;

export const Button = styled.button`
  width: 50%;
  height: 50px;
  min-width: 100px;
  max-width: 250px;
  margin-top: 1em;
  padding: 7px 12px;
  color: ${constants.white};
  font-size: 16px;
  background-color: ${constants.primary};
  border: 0px;
  border-radius: 7px;
  appearance: none;
  cursor: pointer;
  &:nth-child(1) {
    margin-right: 10px;
  }
`;
export const SubmitButton = styled(Button)`
  background-color: ${constants.success};
  width: 100%;
  height: 3.5em;
  font-size: 16pt;
`;
export const ButtonAnotherPhoto = styled(Button)`
  color: ${constants.primary};
  background-color: transparent;
  font-size: 12pt;
  text-decoration: underline;
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
// export const Snap = styled.button`
//   border: none;
//   background-color: ${constants.primary};
//   border-radius: 45px;
//   width: 90px;
//   height: 90px;
//   font-size: 16px;
//   color: ${constants.white};
//   margin-top: 0.5em;
// `;
