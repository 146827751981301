import React from 'react';
import { H, P, Content } from './styles';
import Sanitizer from '../../utils/sanitizer';
const Step = (props) => {
  return (
    <Content dir={props.dir}>
      <H>{props.step}</H>
      <P dangerouslySetInnerHTML={{ __html: Sanitizer(props.text) }}>
        {/* {props.children} */}
      </P>
    </Content>
  );
};
export default Step;
