export default {
  dir: 'rtl',
  openCamera: 'إفتح الكاميرا',
  closeCamera: 'أغلق الكاميرا',
  takePhoto: 'إلتقط صورة',
  takeAnotherPhoto: 'إعادة التقاط الصورة',
  verificationNotFound: 'إن هذا الرابط غير موجود أو منتهي الصلاحية',
  loading: 'جاري التحميل',
  submit: 'أرسل',
  thankyou: 'شكراً على إرسالك الصورة سوف نتحقق منها ونكمل عملية التدقيق',
  error:
    'حصل خطأ أثناء التحقق الرجاء المحاولة مرة أخرى أو الاتصال بمنظمة الامم المتحدة لشؤون اللاجئين',
  android: {
    support: 'ليس بإمكانك إتمام عملية التحقق على هذا المتصفح',
    url1: 'إضغط هنا',
    url2: 'لتنزيل متصفح Chrome على Android',
  },
  ios: {
    support:
      'ليس بإمكانك إتمام عملية التحقق على هذا المتصفح الرجاء إستعمال متصفح Safari',
  },
  notMobile:
    'ليس بإمكانك إتمام عملية التحقيق من خلال الحاسوب يرجى إستعمال الهاتف الذكي',
  notSupported:
    'لا يمكنك إتمام عملية التحقق على هذا الهاتف الرجاء إستعمال أندرويد أو أيفون',
  title: 'UNHCR Remote Verification',
  introText: `<ol>
  <li></strong>إضغط مفتاح <strong>إفتح الكاميرا</li>
  <li>إضغط مفتاح <strong>إلتقط صورة</strong> لأخذ صورة شخصية لك</li>
  <li>اضغط مفتاح <strong>أرسل</strong> لتمكن المفوضيه من تأكيد هويتك</li>
  </ol>
  <u><em>سيتم تخزين صورتك بشكل آمن في ملف المفوضية الخاص بك</em></u>`,
  geo: 'غير مسموح إستعمال خاصية تحديد الموقع، الرجاء السماح بها في الإعدادات',
  media: 'حصل خطأ في الكاميرا الرجاء التأكد أن خاصية الكاميرا يمكن استعمالها',
  submitAnother: 'صورة أخرى',
  confirmAnother: 'هل تريد أن ترسل صورة فرد أخر؟',
  yes: 'نعم',
  no: 'كلا',
  enableLocation: 'تفعيل خدمات تحديد الموقع',
  enableCamera: 'تفعيل خاصية الكاميرا',
  enableLocationCheck: 'التحقق من وجود خدمة تحديد المواقع',
  enableCameraCheck: 'التحقق من وجود الكاميرا',
  homeIntroText: `<h3>ستنتهي صلاحية هذا الرابط خلال <u>30 دقائق</u></h3>
  أن عنوان هذه الصفحة مرسل من قبل مفوضية الامم المتحدة لشؤون اللاجئين في لبنان لغرض تحديث بياناتك عن طريق الهاتف بدل من اضطرارك للقدوم الى مراكز التسجيل التابعة للمفوضية.  لكي تتمكن المفوضية من اتمام العملية يجب ان تتوفر المعطيات ادناه:
  <ol><li>امتلاك هاتف ذكي قابل للوصول الى الانترنت ويحتوي على كاميرا لالتقاط صورة شخصية.</li>
  <li>امكانية الوصول الى الانترنت عن طريق الهاتف.</li>
  <li>يجب السماح لمتصفح الانترنت الخاص بهاتفك الوصول الى الكاميرا والى معلومات موقعك.</li>
  </ol>
  <em>يرجى تفعيل خاصية الكاميرا وخدمة تحديد المواقع عبر النقر على الازرار في أسفل الصفحة</em>`,
  demoHomeIntroText: `أن عنوان هذه الصفحة مرسل من قبل مفوضية الامم المتحدة لشؤون اللاجئين في لبنان لغرض معرفة امكانية تحديث بياناتك عن طريق الهاتف بدل من اضطرارك للقدوم الى مراكز التسجيل التابعة للمفوضية.  لكي تتمكن المفوضية من اتمام العملية يجب ان تتوفر المعطيات ادناه:
  <ol><li>امتلاك هاتف ذكي قابل الوصول الى الانترنت ويحتوي على كاميرا لألتقاط صورة شخصية.</li>
  <li>امكانية الوصول الى الانترنت اثناء وقت مقابلة تحديث البيانات والتي سوف ترجى عن طريق الهاتف.</li>
  <li>يجب السماح لمتصفح الانترنت الخاص بهاتفك الوصول الى الكاميرا والى معلومات موقعك.</li>
  </ol>`,
  locationSuccess: `لقد تم تفعيل خاصية تحديد الموقع بنجاح`,
  cameraSuccess: `لقد تم تفعيل خاصية الكاميرا بنجاح`,
  steps: {
    first: `الخطوة الأولى:`,
    enableCamera: `التأكد من تفعيل وصول المتصفح الى كاميرا الهاتف
    اضغط على الزر ادناه للتحقق, سيقوم المتصفح بالسؤال عن رغبتك بوصول المتصفح الى كاميرا الهاتف, الرجاء اضغط على اختيار <strong>السماح</strong>
    `,
    second: `الخطوة الثانية:`,
    enableLocation: `التأكد من تفعيل وصول المتصفح الى خدمة تحديد المواقع
    اضغط على الزر ادناه للتحقق, سيقوم المتصفح بالسؤال عن رغبتك بوصول المتصفح الى خدمات المواقع, الرجاء اضغط على اختيار <strong>السماح</strong>
    `,
    last: `الخطوة الاخيرة:`,
    testCamera: `التأكد من قابلية ارسال صورة تجريبية.<br>
    <em>تنويه: لن يتم تخزين هذه الصورة وهي للتجربة فقط.</em>
    `,
  },
  successText: `لقد تم التحقق من ان جهاز الهاتف لديك يمكن ان يستخدم لإجراء مقابلة تحديث بيانات ملفك عن طريق الهاتف بنجاح.<br>
  يرجى تواجد جميع الافراد المسجلين على الملف في يوم المقابلة الذي اعطي لكم بتاريخ:
   $APTDATE$ للتمكن من تحديث بياناتكم
  `,
  errorRefresh: `لقد حصل خطأ، الرجاء إعادة تحميل الصفحة والمحاولة مرة أخرى`,
  nf: `لم نستطيع تحديد صورة الوجه بنجاح, الرجاء ارسال صورة تظهر وجه الشخص بوضوح `,
  bp: `لقد حصل خطأ أثناء ارسال الصورة, يرجى معاودة التقاط الصورة مرة اخرى`,
  ok: `حسناً`,
  tryAgain: `حاول مرة اخرى`,
  blocked: {
    ios: {
      location: `<strong>تعذر الوصول الى خدمات الموقع:</strong><br>
      يرجى التأكد من تفعيل خدمات الموقع على جهازك<br>
      اذهب الى: <strong>الإعدادات</strong> -> <strong>الخصوصية</strong> -> <strong>خدمات الموقع</strong> (يجب ان تكون في وضع التشغل)
      `,
      camera: `<strong>تعذر الوصول الى كاميرا الهاتف:</strong><br>
      يرجى التأكد من وصول متصفح الانترنت سفاري الى كاميرا جهازك<br>
      أذهب الى: <strong>الإعدادات</strong> -> <strong>Safari</strong> ->  انتقل الى منطقة <u>الإعدادت للمواقع</u> -> <strong>كاميرا</strong> >  اختر <strong>السماح</strong>
      `,
    },
    android: {
      firefox: {
        location: `<strong>Location is blocked:</strong>
      Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> > <strong>Privacy</strong> > <strong>Location Services</strong> (should be On)
      `,
      },
      chrome: {
        location: `<strong>Location is blocked:</strong>
      1- Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)<br>
      2- Make sure the location is not blocked on Chrome browser
      On the browser tap on:
      on the top right corner -> <strong>Settings</strong> -> <strong>Site Settings</strong> -> <strong>Location</strong>
      `,
        camera: `<strong>Location is blocked:</strong>
      1- Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> -> <strong>Privacy</strong> -> <strong>Location Services</strong> (should be On)<br>
      2- Make sure the location is not blocked on Chrome browser
      On the browser tap on:
      on the top right corner -> <strong>Settings</strong> -> <strong>Site Settings</strong> -> <strong>Location</strong>
      `,
      },
      other: {
        location: `<strong>Location is blocked:</strong>
      Make sure the location service is enabled on the Operating System
      Go to: <strong>Settings</strong> > <strong>Privacy</strong> > <strong>Location Services</strong> (should be On)
      `,
      },
    },
  },
};
