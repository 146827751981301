import React from 'react';
import { Root, P, Img, A } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Sanitizer from '../../utils/sanitizer';
import constants from '../../utils/constants';
import chrome from './chrome.svg';

const NoWebRTC = (props) => {
  return (
    <Root>
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size='4x'
        color={constants.danger}
      ></FontAwesomeIcon>
      <P
        dir={props.dir}
        dangerouslySetInnerHTML={{ __html: Sanitizer(props.text) }}
      >
        
      </P>
      {!props.ios && (
        <A href={props.link} ><Img src={chrome} /></A>
      )}
    </Root>
  );
};

export default NoWebRTC;
