import './utils/polyfills';
import adapter from 'webrtc-adapter';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

const log = console.log;
console.logx = (msg) => {
  axios.post(`https://log.unhcr-lb.org`, {
    log: msg,
  });
  log('Message:', msg);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
