import styled from 'styled-components';

export const Heading = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: ${({ demo }) => (demo ? `#f5d742` : `#0072bc`)};
  h1 {
    font-size: large;
    color: ${({ demo }) => (demo ? `#21201c` : `#f7f7f7`)};
  }
`;

// background-color: #0072bc;
