import React from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import NotMobile from '../NotSupported/NotMobile';
import IOS from '../NotSupported/IOS';
import Android from '../NotSupported/Android';
import Other from '../NotSupported/Other';

const BrowserNotSupported = (props) => {
  console.log('BrowserNotSupported');
  if (!isMobile) {
    return <NotMobile {...props.data} />;
  } else {
    if (isIOS) return <IOS {...props.data} />;
    if (isAndroid) return <Android {...props.data} />;
    return <Other {...props.data} />;
  }
};

export default BrowserNotSupported;
