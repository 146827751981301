import styled from 'styled-components';
import constraints from '../../utils/constants';
export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 0 100px;
`;
export const P = styled.p`
  color: ${constraints.primary};
  font-size: 17px;
`;
