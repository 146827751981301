import React from 'react';
import { Message } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import constants from '../../utils/constants';

const CheckResult = (props) => {
  let content = null;
  if (props.dir.toLowerCase() === 'ltr') {
    content = (
      <>
        <p>{props.text}</p>
        <FontAwesomeIcon icon={faCheck} size='2x' color={constants.success} />
      </>
    );
  } else {
    content = (
      <>
        <FontAwesomeIcon icon={faCheck} size='2x' color={constants.success} />
        <p>{props.text}</p>
      </>
    );
  }
  return <Message dir={props.dir}>{content}</Message>;
};

export default CheckResult;
