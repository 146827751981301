import React from 'react';
import LocalizedStrings from 'localized-strings';
import { Root, Header } from './styles';
import stringsToLocalize from '../../localisation';

const strings = new LocalizedStrings(stringsToLocalize);
const Error = (props) => {
  strings.setLanguage(props.Language);
  return (
    <Root>
      <Header>{strings.error}</Header>
    </Root>
  );
};

export default Error;
