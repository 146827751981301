import styled from 'styled-components';
import constants from '../../utils/constants';

export const Root = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
`;
export const P = styled.p`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    font-size: 2em;
    color: ${constants.danger};
`;
export const Button = styled.button`
  width: 50%;
  height: 50px;
  min-width: 100px;
  max-width: 250px;
  margin-top: 1em;
  padding: 7px 12px;
  color: ${constants.white};
  font-size: 16px;
  background-color: ${constants.primary};
  border: 0px;
  border-radius: 7px;
  appearance: none;
  cursor: pointer;
  &:nth-child(1) {
    margin-right: 10px;
  }
`;
