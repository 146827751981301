import React from 'react';
import Sanitizer from '../../utils/sanitizer';
import Step from '../Step';
import { DIV } from './styles';

const MainPageContent = (props) => {
  let content = (
    <DIV
      dir={props.dir}
      dangerouslySetInnerHTML={{ __html: Sanitizer(props.IntroText) }}
    ></DIV>
  );
  if (props.demo) {
    content = (
      <Step dir={props.dir} step={props.step} text={props.stepContent}></Step>
    );
  }
  return content;
};

export default MainPageContent;
