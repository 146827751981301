import styled from 'styled-components';
import constants from '../../utils/constants';

export const Root = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 3em;
  img {
    width: 40%;
  }
  margin-bottom: 1em;
`;
export const Instructions = styled.div`
  p {
    color: ${constants.primary};
    font-size: 17px;
  }
  border-bottom: 1px solid ${constants.lightGrey};
  margin-bottom: 0.5em;
`;
export const Button = styled.button`
  width: 100%;
  min-width: 100px;
  max-width: 250px;
  margin-bottom: 10px;
  padding: 7px 12px;
  color: #f7f7f7;
  background-color: ${constants.primary};
  border: 0px;
  border-radius: 7px;
  appearance: none;
  cursor: pointer;
  margin-left: 0.5em;
  margin-right: 0.5em;
  p {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 10px;
    width: 100%;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.35em;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Scrollable = styled.div`
  overflow-y: ${({ scroll }) => (scroll ? `auto` : `none`)};
  height: ${({ height }) => height && `${height}px`};
  padding-bottom: 10em;
  margin-top: 0.5em;
  width: 100%;
`;
export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  button {
    margin-top: 1em;
    margin-bottom: 1.5em;
  }
`;
