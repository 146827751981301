import styled from 'styled-components';
import constants from '../../utils/constants';
export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5em 0 2em;
`;
export const P = styled.p`
  color: ${constants.primary};
  font-size: 2em;
  a {
    text-decoration: none;
  }
`;
export const Img = styled.img`
width: 4em;
height: 4em;
`;
export const A = styled.a`
text-decoration: none;
`;