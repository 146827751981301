import React from 'react';
import { Root, Header } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import stringsToLocalize from '../../localisation';
import Sanitizer from '../../utils/sanitizer';

const notFound = Object.keys(stringsToLocalize).map((lang) => {
  return `<h2>${stringsToLocalize[lang].verificationNotFound}</h2>`;
});
const Page404 = (props) => {
  return (
    <Root>
      <Header>
        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
      </Header>
      <div
        dir={props.dir}
        dangerouslySetInnerHTML={{ __html: Sanitizer(notFound.join('')) }}
      ></div>
    </Root>
  );
};

export default Page404;
