import React from 'react';
import {P, Button, Root} from './styles';

const MessageBox = (props) => {
    return(
    <Root>
        <P>{props.message}</P>
        <Button onClick={props.onClose}>{props.ok}</Button>
    </Root>
    );
}

export default MessageBox;