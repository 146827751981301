import styled from 'styled-components';
export const Message = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: #00b398;
  dir: ${(dir) => dir};
  p {
    font-size: 18px;
  }
`;
