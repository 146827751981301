import React from 'react';
import sanitizer from '../../../utils/sanitizer';
import { Root, P } from '../styles';

const IOs = (props) => {
  let text = `BLOCKED IOS`;
  if (props.locationBlocked) {
    text = props.text.ios.location;
  } else if (props.cameraBlocked) {
    text = props.text.ios.camera;
  }
  return (
    <Root>
      <P
        dir={props.dir}
        dangerouslySetInnerHTML={{
          __html: sanitizer(text),
        }}
      ></P>
    </Root>
  );
};

export default IOs;
