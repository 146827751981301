import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
  }

  body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 32px;
    margin: 0;
    padding: 0;
  }
  div#root {
    height: 100%;
  }
`;
export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 0 100px;
`;
export const Header = styled.h1`
  color: red;
  font-size: 40px;
`;
export const URL = styled.a`
  font-size: 30px;
  text-decoration: none;
  cursor: pointer;
`;
