import React, { useState, useRef, Fragment, useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faCamera } from '@fortawesome/free-solid-svg-icons';
import { isIOS } from 'react-device-detect';
import {
  Root,
  Button,
  Content,
  Buttons,
  Scrollable,
  Instructions,
} from './styles';
import Header from '../Header';
import Verification from '../Verification';
import Step from '../Step';
import Logo from '../Logo';
import CheckResult from '../CheckResult';
import IntroInstructions from '../IntroInstructions';
import Blocked from '../Blocked';
import NoWebRTC from '../NoWebRTC';
import stringsToLocalize from '../../localisation';
import config from '../../config';

const PermissionPage = (props) => {
  const [locationEnabled, setLocationEnabled] = useState(false);
  const [locationBlocked, setLocationBlocked] = useState(false);
  const [cameraBlocked, setCameraBlocked] = useState(false);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [hasWebRTC, setHasWebRTC] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [scroll, setScroll] = useState(true);

  // const scroll = useRef();
  const introText = useRef();

  let content = null;
  const constraints = {
    audio: false,
    video: {
      facingMode: 'user',
    },
  };
  const strings = new LocalizedStrings(stringsToLocalize);
  let lang = 'En';
  if (props.data.Language) {
    lang = props.data.Language;
  }
  strings.setLanguage(lang);
  let IntroText = null;
  if (props.IntroText) {
    introText.current = props.IntroText;
  }
  // else if (props.data.IntroText) {
  //   introText.current = props.data.IntroText;
  // }
  if (
    introText.current &&
    introText.current !== '' //&& !props.demo
  ) {
    IntroText = introText.current;
  } else {
    IntroText = props.demo ? strings.demoHomeIntroText : strings.homeIntroText;
  }
  const checkPermission = async () => {
    if (navigator.permissions) {
      if (!locationEnabled && !(locationBlocked && cameraBlocked)) {
        navigator.permissions
          .query({
            name: 'geolocation',
          })
          .then((result) => {
            setLocationEnabled(result.state === 'granted');
            result.onchange = () => {
              setLocationEnabled(result.state === 'granted');
              setLocationBlocked(result.state === 'denied');
            };
          });
      }
      if (!cameraEnabled && !(locationBlocked && cameraBlocked)) {
        navigator.permissions
          .query({
            name: 'camera',
          })
          .then((result) => {
            setCameraEnabled(result.state === 'granted');
            result.onchange = () => {
              setCameraEnabled(result.state === 'granted');
              setCameraBlocked(result.state === 'denied');
            };
          });
      }
    }
  };
  useEffect(() => {
    if (!cameraBlocked && !locationBlocked) {
      checkPermission();
    }
  });
  const handleEnableLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        if (!showLogo) setShowLogo(true);
        setLocationEnabled(true);
      },
      (err) => {
        console.logx(err);
        if (err) {
          if (err.code === 1) {
            setLocationBlocked(true);
          }
          setLocationEnabled(false);
        }
      }
    );
  };
  const handleEnableCamera = async () => {
    var hasRTC = navigator.mediaDevices;
    hasRTC = hasRTC === undefined ? false : true;
    if (hasRTC) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
          if (!showLogo) setShowLogo(true);
          setCameraEnabled(true);
        })
        .catch((err) => {
          if (err.message === 'Permission denied') setCameraBlocked(true);
          setCameraEnabled(false);
        });
    } else {
      setHasWebRTC(false);
    }
  };

  if (!hasWebRTC) {
    const link = `${config.googleStoreURL}&hl=${lang}`;
    let text = strings.NoWebRTC || 'No Web RTC';
    if (!isIOS) {
      text = `${strings.android.support} <a href=${link}>${strings.android.url1}</a> ${strings.android.url2}`;
    } else {
      text = strings.ios.support;
    }
    return (
      <Fragment>
        <Header title={strings.title} demo={props.demo} />
        <NoWebRTC dir={strings.dir} ios={isIOS} link={link} text={text} />
      </Fragment>
    );
  }
  if (cameraBlocked || locationBlocked) {
    return (
      <Fragment>
        <Header title={strings.title} demo={props.demo} />
        <Blocked
          locationBlocked={locationBlocked}
          cameraBlocked={cameraBlocked}
          dir={strings.dir}
          text={strings.blocked}
        />
      </Fragment>
    );
  }
  let step1 = (
    <Button onClick={handleEnableCamera}>
      <span>
        <FontAwesomeIcon size='3x' icon={faCamera} />
      </span>
      <p>{props.demo ? strings.enableCameraCheck : strings.enableCamera}</p>
    </Button>
  );
  let step2 = (
    <Button onClick={handleEnableLocation}>
      <span>
        <FontAwesomeIcon size='3x' icon={faLocationArrow} />
      </span>
      <p>{props.demo ? strings.enableLocationCheck : strings.enableLocation}</p>
    </Button>
  );
  if (props.demo) {
    step1 = (
      <>
        <Step
          dir={strings.dir}
          step={strings.steps.first}
          text={strings.steps.enableCamera}
        ></Step>
        {step1}
      </>
    );
    step2 = (
      <>
        <Step
          dir={strings.dir}
          step={strings.steps.second}
          text={strings.steps.enableLocation}
        ></Step>
        {step2}
      </>
    );
  }

  const Xk = (
    <>
      <Instructions>
        <IntroInstructions IntroText={IntroText} dir={strings.dir} />
      </Instructions>
      <div>
        <Buttons>
          {!cameraEnabled && step1}
          {cameraEnabled && (
            <CheckResult dir={strings.dir} text={strings.cameraSuccess} />
          )}
          {!locationEnabled && step2}
          {locationEnabled && (
            <CheckResult dir={strings.dir} text={strings.locationSuccess} />
          )}
        </Buttons>
      </div>
    </>
  );
  if (!cameraEnabled || !locationEnabled) {
    content = <Fragment>{Xk}</Fragment>;
  } else {
    content = (
      <Verification
        demo={props.demo}
        {...props.data}
        setShowLogo={setShowLogo}
        setScroll={setScroll}
      />
    );
  }
  return (
    <Fragment>
      <Root>
        <Header title={strings.title} demo={props.demo} />
        <Scrollable scroll={scroll} height={window.innerHeight + 60}>
          <Content>{showLogo && <Logo />}</Content>
          {content}
        </Scrollable>
      </Root>
    </Fragment>
  );
};

export default PermissionPage;
