import React from 'react';
import sanitizer from '../../../utils/sanitizer';
import { Root, P } from '../styles';

const firefox = (props) => {
  return (
    <Root>
      <P
        dir={props.dir}
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            props.text.android.firefox.location || `BLOCKED Android Firefox`
          ),
        }}
      ></P>
    </Root>
  );
};

export default firefox;
