import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -ms-overflow-style: none;
  }
  
  html {
    height: 100%;
  }
::-webkit-scrollbar {
    display: none;
}

  body {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    padding: 32px;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
  }

  div#root {
    height: 100%;
  }
`;
