import React from 'react';
import sanitizer from '../../../utils/sanitizer';
import { Root, P } from '../styles';
const Chrome = (props) => {
  console.log(props);
  let text = `BLOCKED Android Chrome`;
  if (props.locationBlocked) {
    text = props.text.android.chrome.location;
  } else if (props.cameraBlocked) {
    text = props.text.android.chrome.camera;
  }
  return (
    <Root>
      <P
        dir={props.dir}
        dangerouslySetInnerHTML={{
          __html: sanitizer(text),
        }}
      ></P>
    </Root>
  );
};
export default Chrome;
