import React, { Fragment, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Button, Footer, X } from './styles';
import LocalizedStrings from 'react-localization';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Camera } from '../Camera';
import DemoThankYou from '../ThankYou/Demo';
import ThankYou from '../ThankYou/Live';
import Loading from '../Loading';
import Error from '../Error';
import PermissionError from '../PermissionError';
import MainPageContent from '../MainPageContent';
import SwitchCameraButton from '../SwitchCameraButton';
import MessageBox from '../MessageBox';
import config from '../../config';

import stringsToLocalize from '../../localisation';
import constants from '../../utils/constants';

const Verification = (props) => {
  const strings = new LocalizedStrings(stringsToLocalize);
  const [isCameraOpen, setIsCameraOpen] = useStateWithCallbackLazy(false);
  const [cameraModa, setCameraMode] = useStateWithCallbackLazy('self');
  const [photoSubmitted, setPhotoSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState(null);
  const [noFace, setNoFace] = useStateWithCallbackLazy(false);
  const introText = useRef();
  const additional = useRef(false);
  const pa = useRef(true);

  let lang = 'En';
  if (props.Language) {
    lang = props.Language;
  }
  if (props.Members !== 'PA') {
    additional.current = true;
  }
  strings.setLanguage(lang);
  if (props.IntroText) {
    introText.current = props.IntroText;
  }
  let IntroText = null;
  if (introText.current && introText.current !== '') {
    IntroText = introText.current;
  } else {
    IntroText = strings.introText;
  }
  const submitVerification = async (blob, location, last = true) => {
    let data = new FormData();
    data.append('media', blob);
    data.append('location', JSON.stringify(location));
    data.append('faceExtract', props.FaceExtract);
    setIsCameraOpen(false);
    let axiosConfig = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    };
    let guid = props.GUID;
    let baseURL = config.baseURL;

    let url = `${baseURL}/web/${guid}`;
    setLoading(true);
    let result = null;
    try{
      result = await axios.post(url, data, axiosConfig);
      setLoading(false);
      if(result.data.errors){
        setMessage(strings[result.data.errors[0].msg]);
        setPhotoSubmitted(false);
      }else{
        setMessage(null);
        pa.current = false;
        if(last){
          setPhotoSubmitted(true);
          let urlx = `${baseURL}/web/done/${guid}`;
          axios.post(urlx);
        }else{
          setIsCameraOpen(true);
        }
      }
    }catch(e){
        console.log(e);
        setLoading(false);
        setError(true);
    }
  };
  const submitAdditionalVerification = async (blob, last) => {
    let data = new FormData();
    data.append('media', blob);
    data.append('faceExtract', props.FaceExtract);
    setIsCameraOpen(false);
    let axiosConfig = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    };
    let guid = props.GUID;
    let baseURL = config.baseURL;

    let url = `${baseURL}/web/additional/${guid}`;
    setLoading(true);
    let result = null;
    try{
      result = await axios.post(url, data, axiosConfig);
      setLoading(false);
      if(result.data.errors){
        setMessage(strings[result.data.errors[0].msg]);
        setPhotoSubmitted(false);
      }else{
        setMessage(null);
        pa.current = false;
        if(last){
          setPhotoSubmitted(true);
          let urlx = `${baseURL}/web/done/${guid}`;
          axios.post(urlx);
        }else{
          setIsCameraOpen(true);
        }
      }
    }catch(e){
        console.log(e);
        setLoading(false);
        setError(true);
    }
  };
  const handleSwitchCamera = () => {
    setIsCameraOpen(false, () => {
      setCameraMode(cameraModa === 'self' ? 'environment' : 'self', () => {
        setIsCameraOpen(true);
      });
    })
    
  }
  const handleCloseMessageBox = () => {
    setMessage(null);
    setIsCameraOpen(true);
  }
  const submit = async (blob, location) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h3 style={{textAlign: 'center'}}>{strings.submitAnother}</h3>
            <p style={{fontSize: '15pt'}}>{strings.confirmAnother}</p>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
              }}>
              <Button  textColor='danger'
              height='3em'
              width='40%'
              onClick={async() => {
                onClose();
                if(pa.current){
                  await submitVerification(blob, location, true);
                }else{
                  await submitAdditionalVerification(blob, true);
                }
              }}>{strings.no}</Button>
              <Button textColor='success'
              height='3em'
              width='40%'
                onClick={
                  async() => {
                    onClose();
                  if(pa.current){
                    await submitVerification(blob, location, false);
                  }else{
                    await submitAdditionalVerification(blob, false);
                  }
                  
                }}
              >
                {strings.yes}
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <Fragment>
      {(!loading && message) && <MessageBox message={message} onClose={handleCloseMessageBox} ok={strings.tryAgain}></MessageBox>}
      {(loading || isLoading) && <Loading />}
      {!loading && !photoSubmitted && !error && !hasError && !message && (
        <Fragment>
          {!isCameraOpen && (
            <MainPageContent
              demo={props.demo}
              dir={strings.dir}
              step={strings.steps.last}
              stepContent={strings.steps.testCamera}
              IntroText={IntroText}
            />
          )}
        {isCameraOpen && !isLoading && (
        <X>
          <SwitchCameraButton onClick={handleSwitchCamera} />
          <FontAwesomeIcon size='2x' color={constants.lightGrey} onClick={() => {
            setIsCameraOpen(false);
            props.setShowLogo(true);
            props.setScroll(true);
          }} icon={faTimes} >
          </FontAwesomeIcon>
        </X>
        )}
          {isCameraOpen && !message && (
            <Camera
              cameraMode = {cameraModa}
              onCapture={(blob) => {
                // setCardImage(blob);
              }}
              onSubmit={additional.current ? submit : submitVerification}
              onClear={() => {
                // setCardImage(undefined);
              }}
              Language={props.Language}
              onError={(error) => {
                setHasError(error);
              }}
              setIsLoading={setIsLoading}
            />
          )}
          <Footer>
            {!isCameraOpen && !isLoading && (
              <Button
                onClick={() => {
                  setIsLoading(true);
                  setIsCameraOpen(true);
                  props.setShowLogo(false);
                  props.setScroll(false);
                }}
              >
                <FontAwesomeIcon size='2x' icon={faCamera} />
                <br />
                {strings.openCamera}
              </Button>
            )}
          </Footer>
        </Fragment>
      )}
      {!loading &&
        photoSubmitted &&
        !isLoading && !message &&
        (props.demo ? <DemoThankYou {...props} /> : <ThankYou {...props} />)}
      {!loading && error && !isLoading && !message  && <Error {...props} />}
      {!loading && hasError && !isLoading && !message  && (
        <PermissionError permissionError={hasError} {...props} />
      )}
    </Fragment>
  );
};

export default Verification;
