import React, { useRef } from 'react';
import { Image, Content } from './styles';
import UNHCRLogo from '../../res/unhcr-logo.png';

const Logo = (props) => {
  const logoRef = useRef();
  return (
    <Content>
      <Image ref={logoRef} src={props.logo ? props.logo : UNHCRLogo} />
    </Content>
  );
};
export default Logo;
