import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
export const H = styled.h1`
  color: #0072bc;
`;
export const P = styled.p`
  color: #0072bc;
`;
