import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { GlobalStyle } from './Styles';
import Intro from './components/Intro';
const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/:guid' component={Intro} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
